import logo from "./logo.svg";
import "./App.css";
import { useApi } from "./api";
import { useEffect, useState } from "react";
import { RegisteredPage } from "./pages/RegisterPage";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ProfilePage } from "./pages/ProfilePage";
import useIsAuthenticated from "./hooks/useIsAuthenticated";



// Responsibility - Router, tells the react app what components to go to when a certain route is received
// i.e., /profile -> <Profile />
// SRP - Single Responsibility Principle
function App() {
  const api = useApi();
  const [users, setUsers] = useState();
  const [counter, setCounting] = useState(0);

  useEffect(() => {
    async function getUsers() {
      const response = await api.get("users");
      console.log(response);
      setUsers(response?.result)
    }
    getUsers();
  }, [api]);
  /*div in return here is not necessary but for styling */
  return (

    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<RegisteredPage/>}/>
          <Route path="/signup" element={<RegisteredPage/>}/>
          <Route path="/user/:id" element={<PrivateRoute> 
            <ProfilePage />
          </PrivateRoute>} />
        </Routes>
      </Router>
    </div>
  );
}


// Responsibility - Check to see if user is authenticated, allowing them to access the rest of the app if they are otherwise
// we kick them to the login screen
const PrivateRoute = (props) => {
  
  // check localstorage for token
  // if token exists, then they can go to the route
  const isAuthenticated = useIsAuthenticated();

  if(!isAuthenticated)
  {
    return <Navigate to="/signup" />
  }

  return props.children;
}


//FizzBuzz
//given a range of number from 0 - n,
// if n is divisible by 3 print Fizz,
// if n is divisible by 5 print Buzz,
// and if n is divisible by 3 and 5, print FizzBuzz


// General:
// general programming
// leetcode <- free tier, paid version
// youtube "programming logic problems"

// backend:
// Express / Node.js -> JS
// Database and data driven <- SQL, MongoDB, GraphQL, Atlas -> JS

// frontend:
// React -> JS
// strong understanding of ES6 Javascript / Typescript

// full-stack: JS



export default App;
