// import { useApi } from "../api";
// import { useState } from "react";
// import Button from "../components/Button";
import React from 'react';

export const ProfilePage = ()=> {
  return <div>
    <span>Hello!</span>
  </div>
}
