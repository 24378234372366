
// this is just checking for the presence of a JWT access token. We don't care if it is actually valid or not. 
// We will let the backend decide if it is valid since that is the only source of truth
const useIsAuthenticated = () => {
    return window.localStorage.getItem("access-token");
}

export default useIsAuthenticated;



// authentication
// trust
// backend, we can trust everything, we can control everything
// frontend, we trust almost nothing

// security concerns are always handled on the backend

// JWT data that enables that communication between front-end and backend.$

// if the JWT exists and the backend says that it is valid, then the user MUST be signed in.
// if the JWT exists and the backend says that it is invalid, then the user IS NOT signed in. JWTs have expiration dates.
// if the JWT does not exist, then user IS NOT signed in.


